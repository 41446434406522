import React from "react"

import resolveVariationClass from "../../helpers/theme-variation-style"

import themeGet from "@styled-system/theme-get"

import styled from "styled-components"
/**
 * TODO: duplicated component - Featured and featured mobile
 * @param {hidetext} hidetext - grandwild website desn't need to have the text as requested
 */

const FeaturedSection = styled.div`
  background: ${themeGet("components.featuredSection.background")};
`

const FeaturedMobile = ({ data, hideText }) => {
  const renderFeatures = () =>
    data
      .sort((a, b) => a.node.order - b.node.order)
      .map((e, idx) => (
        <div key={idx} className="featured__container-image">
          <a href={e.node.logoUrl} target="__blank">
            <img src={e.node.logoImage.localFile.publicURL}></img>
          </a>
        </div>
      ))

  return (
    <FeaturedSection className={resolveVariationClass("featured--mobile")}>
      {!hideText && <h2 className="featured--mobile__heading">featured in</h2>}
      <div className={resolveVariationClass("featured__container")}>
        {renderFeatures()}
      </div>
    </FeaturedSection>
  )
}

export default FeaturedMobile
