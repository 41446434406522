import React from "react"
import resolveVariationClass from "../../helpers/theme-variation-style"
import { getAvailabilityClass, getAvailabilityColorStyleColor } from "./utils"

const Prices = ({
  bookingFormAvailablityClass,
  useYachtClass,
  prices,
  pricesClassOrdered,
}) => {
  return pricesClassOrdered.map((priceClass, idx) => {
    const p = prices.find(p => p.productClass === priceClass.code)
    if (p) {
      return (
        <div
          key={idx}
          className={getAvailabilityClass(
            p.availability,
            "booking-form__price-entry"
          )}
        >
          {useYachtClass ? (
            <div
              className={`mobile-yes heading-5--capitalized ${resolveVariationClass(
                "heading-5"
              )}`}
            >
              {p.productClass}
            </div>
          ) : null}
          <div className={resolveVariationClass("booking-form__price")}>
            <div className="booking-form__entry-price-holder">
              {p.priceB && (
                <span className="booking-form__original">
                  {p.currencySymbol}
                  {p.priceB}
                </span>
              )}
              <span className="booking-form__discount">
                {p.currencySymbol}
                {p.priceA}

                {p.currencyCode && (
                  <span>
                    pp&thinsp;
                    {p.currencyCode}
                  </span>
                )}
              </span>
            </div>
            <div className="booking-form__availability-container">
              <span
                style={getAvailabilityColorStyleColor(p.availability)}
                className={bookingFormAvailablityClass}
              >
                {p.availability}
              </span>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          key={idx}
          className={getAvailabilityClass(
            "Not Available",
            "booking-form__price-entry"
          )}
        >
          <div
            className={`mobile-yes heading-5--capitalized ${resolveVariationClass(
              "heading-5"
            )}`}
          >
            {priceClass.code}
          </div>

          <div
            style={{ gridTemplateColumns: "100%", width: "100%" }}
            className={resolveVariationClass("booking-form__price")}
          >
            <div
              style={{ justifyContent: "center" }}
              className="booking-form__availability-container"
            >
              <span
                style={getAvailabilityColorStyleColor("Not Available")}
                className={bookingFormAvailablityClass}
              >
                Not Available
              </span>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default Prices
