import React from "react"
import { useScrollHandler } from "../../hooks/useScrollHandler"
import resolveVariationClass from "../../helpers/theme-variation-style"
import Announcement from "./announcement"
import styled, { css } from "styled-components"
import themeGet from "@styled-system/theme-get"
import mediaQuery from "../../utils/mediaQuery"

const HeaderBar = styled.div`
  ${props =>
    props.fillBackground &&
    css`
      background-color: ${themeGet("components.header.background")};
    `}

  *::after {
    margin: 0;
  }
  width: 100%;
  position: relative;
  height: 8rem;

  ${mediaQuery.mobile} {
    height: 6.4rem;
    overflow: hidden;
    background-color: ${themeGet("components.header.background")};
  }

  ${mediaQuery.tablet} {
    height: 6.5rem;
  }
`

const HeaderContainer = styled.header`
  .header {
    &__logo {
      & > div:nth-of-type(1) {
        width: ${themeGet("components.logo.width")};
        margin-top: ${themeGet("components.logo.topMargin")};
      }
    }
  }
`

const Header = ({
  children,
  backgroundColorAlwaysActive,
  displayAnnouncementBanner,
}) => {
  const scroll = useScrollHandler()

  const headerCollor = `${resolveVariationClass("header-color")}`

  const className =
    scroll && !backgroundColorAlwaysActive
      ? resolveVariationClass(`header-main`)
      : `${resolveVariationClass(`header-main`)} ${headerCollor}`

  return (
    <HeaderContainer>
      <HeaderBar fillBackground={!scroll || backgroundColorAlwaysActive}>
        {children}
      </HeaderBar>
      {displayAnnouncementBanner && <Announcement />}
    </HeaderContainer>
  )
}

export default Header
