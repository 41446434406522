import React from "react"
import { Link } from "gatsby"

import NavButton from "../header/navButton"
import useMenuLabelQuery from "../../queries/themeMenuLabelConfigQuery"
import resolveVariationClass from "../../helpers/theme-variation-style"

const MobileNavItems = ({ setChecked, checked }) => {
  const menuLabels = useMenuLabelQuery()

  // iterating through link items
  const renderNavItems = () => {
    return menuLabels.map(({ label, link, sub, external }, idx) => {
      return (
        // bear in mind that only the fragments with explictly written React.fragment can be keyed!, in here we added idx+5, to keye our fragments
        <React.Fragment key={idx + 5}>
          <li
            key={idx}
            className={
              idx === 0
                ? `${resolveVariationClass("navigation__link")}`
                : "navigation__link"
            }
          >
            {external ? (
              <a
                onClick={() => setChecked(false)}
                className="navigation__link"
                href={link}
                target="_blank"
              >
                {label}
              </a>
            ) : (
              <Link
                onClick={() => setChecked(false)}
                className="navigation__link"
                to={link}
              >
                {label}
              </Link>
            )}

            {/* if there are subs available, we show them, otherwise, we don't render extra unncessary codes */}
            {sub !== null &&
              sub.length > 0 &&
              sub.filter(s => s.link.length > 1).length > 0 && (
                <ul key={label} className="navigation__dropdown">
                  {sub
                    .filter(s => s.link.length > 1)
                    .map(({ label, link }) => {
                      return (
                        <li key={label} className="navigation__dropdown-item">
                          <Link onClick={() => setChecked(false)} to={link}>
                            {label}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              )}
          </li>
        </React.Fragment>
      )
    })
  }

  return (
    // fragments are used to avoic react parent element error
    <>
      <NavButton />
      {renderNavItems()}
    </>
  )
}

export default MobileNavItems
