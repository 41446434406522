import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Heading from "../../shared/heading"
import resolveVariationClass from "../../helpers/theme-variation-style"
import useThemeRoutesConfigQuery from "../../queries/themeRoutesConfigQuery"
import { useWebSiteConfigQuery } from "../../queries/webSiteConfigQueries"
// TODO - change this component name to something more generic and not whywild

const WhyWild = ({ WhyWildData }) => {
  const whyUsHeaderText = useWebSiteConfigQuery().sitePlugin.pluginOptions
    .config.destinationPage.whyUsHeaderText
  const themeOptionsQueryData = useThemeRoutesConfigQuery()

  const theme = process.env.GATSBY_THEME

  const renderWhyWildData = () => {
    return WhyWildData.sort((a, b) => a.order - b.order).map((why, idx) => {
      return (
        <div key={idx} className="WhyWild-box-single">
          <Img
            className="WhyWild-box-single__image"
            alt={why.title}
            fluid={why.banner.localFile.childImageSharp.fluid}
          />
          <h3 className="WhyWild-box-single__title">{why.title}</h3>
          <p className="WhyWild-box-single__description">
            {why.description.description}
          </p>
        </div>
      )
    })
  }
  return (
    <div className="section-destination__why-wild">
      <Heading
        as="h2"
        className={`${resolveVariationClass(
          "heading-1"
        )} u-margin-bottom-small`}
      >
        {whyUsHeaderText}
      </Heading>
      <div className="WhyWild-box">{renderWhyWildData()}</div>
      <Link
        id={theme === "yg" ? null : "scrollAnchor"}
        to={`${themeOptionsQueryData.vehiclesRoute}`}
        className={resolveVariationClass("acitivity-box-button")}
      >
        {themeOptionsQueryData.vehiclesText}
      </Link>
    </div>
  )
}

export default WhyWild
