import React, { useState } from "react"
import PropTypes from "prop-types"
// import { withPrefix } from "gatsby"

import Footer from "../footer/footer"
import Navigation from "../header/navigation"
import Header from "../header/header"
import AppContext from "../../../app"

//mobile modules
import NavigationMobile from "./../mobile/navigationMobile"

//loading fonts
import "../../styles/fonts/_fonts.css"
import "../../styles/main.scss"

const Layout = ({ children, displayAnnouncementBanner }) => {
  Layout.propTypes = {
    children: PropTypes.node.isRequired,
  }

  return (
    <AppContext>
      <Header displayAnnouncementBanner={displayAnnouncementBanner}>
        <Navigation />
        <NavigationMobile />
      </Header>
      <main>{children}</main>
      <Footer />
    </AppContext>
  )
}

export default Layout
