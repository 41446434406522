export const getAvailabilityClass = (availability, className) => {
  if (availability === "Sold Out" || availability === "Not Available") {
    return `${className} ${className}--soldout`
  }

  return className
}

export const getAvailabilityColorStyleColor = availability => {
  if (availability === "February Sale") {
    return { color: "#fa5a5f" }
  }
  if (availability === "Sale") {
    return { color: "#fa5a5f" }
  }

  return {}
}
