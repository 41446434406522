import React, { useState } from "react"
import { getAvailabilityClass, getAvailabilityColorStyleColor } from "./utils"
import resolveVariationClass from "../../helpers/theme-variation-style"
import Loader from "react-loader-spinner"
import Prices from "./prices"
import { Heading, useWebSiteConfigQuery } from "../../.."
const PricesAlt = ({ prices, useYachtClass, bookingFormAvailablityClass }) => {
  return prices.map((p, idx) => {
    if (p) {
      return (
        <div
          key={idx}
          className={getAvailabilityClass(
            p.availability,
            "booking-form__price-entry"
          )}
        >
          {useYachtClass ? (
            <div
              className={`mobile-yes heading-5--capitalized ${resolveVariationClass(
                "heading-5"
              )}`}
            >
              {p.productClass}
            </div>
          ) : null}
          <div className={resolveVariationClass("booking-form__price")}>
            <div className="booking-form__entry-price-holder">
              {p.priceB && (
                <span className="booking-form__original">
                  {p.currencySymbol}
                  {p.priceB}
                </span>
              )}
              <span className="booking-form__discount">
                {p.currencySymbol}
                {p.priceA}

                {p.currencyCode && (
                  <span>
                    &thinsp;
                    {p.currencyCode}
                  </span>
                )}
              </span>
            </div>
            <div className="booking-form__availability-container">
              <span
                style={getAvailabilityColorStyleColor(p.availability)}
                className={bookingFormAvailablityClass}
              >
                {p.availability}
              </span>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="booking-form__price-entry heading-5" key={idx}>
          NOT AVAILABLE
        </div>
      )
    }
  })
}

/*TEMporary Tweak - Add winter months for aussie destinations*/
/** NOT a PURE funtion intentionally */
const assignWinterMonthsProperty = entry => {
  const destinations = [
    "absolute-aussie",
    "queensland-classic",
    "east-coast-voyager",
  ]

  const monthsToHide = [
    {
      month: 5,
      year: 2020,
    },
    {
      month: 6,
      year: 2020,
    },
    {
      month: 7,
      year: 2020,
    },
    {
      month: 8,
      year: 2020,
    },
    {
      month: 9,
      year: 2020,
    },
    {
      month: 10,
      year: 2020,
    },

    {
      month: 5,
      year: 2021,
    },
    {
      month: 6,
      year: 2021,
    },
    {
      month: 7,
      year: 2021,
    },
    {
      month: 8,
      year: 2021,
    },
    {
      month: 9,
      year: 2021,
    },
  ]

  if (destinations.find(d => d === entry.slug)) {
    entry.months.forEach(month => {
      if (
        monthsToHide.find(m => month.month === m.month && month.year === m.year)
      ) {
        month.winterHide = true
      }
    })
  }
}

const renderMonthWinter = (month, index) => (
  <div key={index} className="booking-form__entry">
    <div className="booking-form__shown">
      <div className="booking-form__intro">
        <span className="booking-form__month">{month.shortDescription}</span>
        <span className={resolveVariationClass("booking-form__base-price")}>
          Closed for winter
        </span>
      </div>
      <div className="booking-form__hidden"></div>
    </div>
  </div>
)

const Entries = ({ entries, useYachtClass, pricesClassOrdered }) => {
  const contactEmail = useWebSiteConfigQuery().contentfulWebsiteConfiguration
    .bookingFormEmailContact

  const [checkerState, setCheckerState] = useState(true)
  const bookingFormAvailablityClass = resolveVariationClass(
    "booking-form__availability"
  )

  if (!entries || !entries.months) {
    return (
      <>
        <Heading className={resolveVariationClass("heading-1")} as="h2">
          Available Dates
        </Heading>
        <p className="intro__description">
          For available dates please call us or email &nbsp;
          <span className="booking-details__email">{contactEmail}</span>
        </p>
      </>
    )
  }

  // assignWinterMonthsProperty(entries)

  return entries.months.map((e, idx) => {
    // if (e.winterHide) {
    //   return renderMonthWinter(e, idx)
    // }

    return (
      <div
        key={idx}
        className={getAvailabilityClass(e.availability, "booking-form__entry")}
      >
        <div className="booking-form__shown">
          <input
            className="booking-form__input"
            id={`plus-holder-${idx + 50}`}
            type="checkbox"
            checked={idx === 0 ? checkerState : undefined}
            onClick={
              idx === 0 ? () => setCheckerState(!checkerState) : undefined
            }
          ></input>
          <label
            className="booking-form__plus-holder"
            htmlFor={`plus-holder-${idx + 50}`}
          >
            <span className="booking-form__angle-arrow"></span>
          </label>
          <div className="booking-form__intro">
            <span className="booking-form__month">{e.shortDescription}</span>
            <span className="booking-form__promo">{e.sale}</span>
            <span className={resolveVariationClass("booking-form__base-price")}>
              <span>from</span> {e.from.currencySymbol}
              {e.from.price} <span>pp {e.from.currencyCode}</span>
            </span>
          </div>
          <div className="booking-form__hidden">
            {useYachtClass ? (
              <div
                className={resolveVariationClass(
                  "booking-form__class-container"
                )}
              >
                {pricesClassOrdered.map((p, idx) => {
                  return <h4 key={idx}>{p.description}</h4>
                })}
              </div>
            ) : null}
            {e.dates.map((d, idx2) => (
              <div
                key={idx2}
                /*onClick={useYachtClass ? null : _ => handleClick()}*/
                className={resolveVariationClass(
                  "booking-form__hidden-entries"
                )}
              >
                <div className="booking-form__left">
                  <div className="booking-form__date-container">
                    <span className="booking-form__date">
                      {d.startDateShort}
                    </span>
                    <span
                      className={resolveVariationClass(
                        "booking-form__destination"
                      )}
                    >
                      {useYachtClass ? (
                        <>
                          Departs {d.startLocation}
                          &thinsp; &#9679; &thinsp;
                          {d.durationInDays} days&thinsp;
                        </>
                      ) : (
                        <>
                         
                          {d.startLocation} to {d.endLocation}
                          &thinsp; &#9679; &thinsp;
                          {d.durationInDays} days&thinsp;
                        </>
                      )}
                      <span>
                        &#9679; &thinsp; {d.startDay} to {d.endDay}
                      </span>
                    </span>
                  </div>
                </div>
                <div className={resolveVariationClass("booking-form__right")}>
                  {useYachtClass ? (
                    <Prices
                      useYachtClass={useYachtClass}
                      prices={d.prices}
                      pricesClassOrdered={pricesClassOrdered}
                      bookingFormAvailablityClass={bookingFormAvailablityClass}
                    />
                  ) : (
                    <PricesAlt
                      prices={d.prices}
                      useYachtClass={useYachtClass}
                      bookingFormAvailablityClass={bookingFormAvailablityClass}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  })
}

export default Entries
