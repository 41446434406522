import React from "react"

import resolveVariationClass from "../../helpers/theme-variation-style"
import NavItems from "./navItems"

const Navigation = () => {
  // calling our custom hook

  return (
    <>
      {/* updating nav className based on user scroll */}
      <nav className="navigation__nav">
        <ul className={resolveVariationClass("navigation__list")}>
          <NavItems />
        </ul>
      </nav>

      {/*<div
        className={scroll ? "wrapper" : `wrapper--${process.env.GATSBY_THEME}`}
     />*/}
    </>
  )
}

export default Navigation
