import styled, { css } from "styled-components"
import themeGet from "@styled-system/theme-get"

const Heading = styled.h1`

  

${props =>
  !props.defaultFont &&
  !props.title &&
  css`
    font-family: ${themeGet("fonts.primary")};
  `}

  
  ${props =>
    props.title &&
    css`
      font-family: ${themeGet("components.heading.title.font")};
    `} 
  
  color: ${themeGet("colors.primary")};

  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `} 


    ${props =>
      props.variation &&
      css`
        color: ${themeGet(`colors.${props.variation}`)};
      `}

      ${props =>
        props.as &&
        props.as === "h2" &&
        css`
          font-family: ${themeGet("components.heading.h2.font")};
        `}

`

export default Heading
