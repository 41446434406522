import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { resolveFluid, resolveFixed } from "../../hooks/useContentfulImage"

const Image = ({ image, ...props }) => {
  if (!image) return null
  let x = 0
  let y = 0
  let objectPosition = props.objectPosition ?? undefined

  if (props.focalPoint && image.width && image.height) {
    x =
      Math.floor((props.focalPoint.focalPoint.x / image.width) * 10000) / 100 ??
      0
    y =
      Math.floor((props.focalPoint.focalPoint.y / image.height) * 10000) /
        100 ?? 0
    objectPosition = `${x}% ${y}%`
  }

  if (props.type === "fluid") {
    const options = (({ maxWidth, maxHeight, toFormat, aspectRatio }) => ({
      maxWidth,
      maxHeight,
      aspectRatio,
      toFormat,
    }))(props)

    const asset = resolveFluid(image, options)
    return (
      <>
        {asset && (
          <Img fluid={asset} {...props} objectPosition={objectPosition} />
        )}
      </>
    )
  }

  if (props.type === "fixed") {
    const options = (({ width, height, toFormat }) => ({
      width,
      height,
      toFormat,
    }))(props)
    const asset = resolveFixed(image, options)
    return (
      <>
        {asset && (
          <Img fixed={asset} {...props} objectPosition={objectPosition} />
        )}
      </>
    )
  }

  return null
}

// interface Props extends GatsbyImageOptionalProps {
//   image: Contentful_Asset | null | undefined;
//   type: "fixed" | "fluid";
//   width: number | undefined;
//   height: number | undefined;
//   maxWidth: number | undefined;
//   aspectRatio?: number;
//   maxHeight: number | undefined;
//   background?: boolean;
//   toFormat: string | undefined;
//   objectFit?: `fill` | `contain` | `cover` | `none` | `scale-down`;
//   objectPosition?: string;
//   focalPoint?: {
//     focalPoint: {
//       x: number;
//       y: number;
//     };
//   };
// }

Image.defaultProps = {
  width: undefined,
  height: undefined,
  maxWidth: undefined,
  maxHeight: undefined,
  toFormat: undefined,
}

// export default Image;
export default React.memo(Image)
