import React from "react"
import resolveVariationClass from "../../helpers/theme-variation-style"
import styled from "styled-components"
import Heading from "../../shared/heading"
import themeGet from "@styled-system/theme-get"



const TourTitle = styled(Heading)`
  font-family: ${themeGet("page.tour.title.font")};
`

const destinationStarter = ({ title, body, CSSModifier }) => {
  return (
    <div className="section-destination__starter">
      <div className="destination-starter">
        <TourTitle
          as="h1"
          className={`${resolveVariationClass(
            "heading-1"
          )} u-margin-bottom-small`}
        >
          {title}
        </TourTitle>
        <p className="destination-starter__body">{body}</p>
      </div>
    </div>
  )
}

export default destinationStarter
