import React from "react"
import { withPrefix } from "gatsby"

import Heading from "../../shared/heading"
import { useWebSiteConfigQuery } from "../../queries/webSiteConfigQueries"

import resolveVariationClass from "../../helpers/theme-variation-style"
import Entries from "./entries"

const getAvailabilityColorStyleColor = availability => {
  if (availability === "February Sale") {
    return { color: "#fa5a5f" }
  }
  if (availability === "Sale") {
    return { color: "#fa5a5f" }
  }

  return {}
}

const renderPriceHeaderDescription = priceTableHeaderDescription => {
  return priceTableHeaderDescription.map((desc, idx) => {
    return (
      <div key={idx} className="booking-form__info-entry">
        <svg className={`svg-icon--MSIncludes`}>
          <use xlinkHref={withPrefix(`sprite.svg#${desc.icon}`)} />
        </svg>
        <p dangerouslySetInnerHTML={{ __html: desc.text }}></p>
      </div>
    )
  })
}

// render buyerInfo
const renderBookingExtraNotes = entries => {
  if (entries === null) {
    return null
  }

  if (entries) {
    return (
      <p className="booking-form__additional-info u-margin-top-small ">
        {entries.booking_notes}
      </p>
    )
  }
}

const PriceTable = ({ mtiPrices, slug }) => {
  const bookingFormConfig = useWebSiteConfigQuery().sitePlugin.pluginOptions
    .config.bookingForm

  const priceTableHeaderDescription = [
    ...useWebSiteConfigQuery().contentfulWebsiteConfiguration
      .priceTableHeaderDescription,
  ]

  //@todo this is a workaround - this needs to move to destinations
  if (slug === "croatia-discovery") {
    if (
      !priceTableHeaderDescription.find(c => c.icon === "discovery-route-icon")
    )
      priceTableHeaderDescription.push({
        icon: "discovery-route-icon",
        text: `Our Croatia Discovery route operates July - August. If you would like to sail Croatia outside of these dates our <a href="/sail-croatia" class="link link--ms">Voyager routes</a>  operate from June - September.`,
      })
  }

  //@todo this is a workaround - this needs to move to destinations
  if (slug === "greece-cyclades-explorer") {
    const sevenNightsIconIndex = priceTableHeaderDescription.findIndex(
      c => c.icon === "yg-price-table-calendar"
    )
    if (sevenNightsIconIndex !== -1) {
      priceTableHeaderDescription[sevenNightsIconIndex] = {
        icon: "yg-price-table-yacht-6-days",
        text: `Six nights accommodation on your own Catamaran`,
      }
    }
  }

  //TODO:This should come from adata.pi somehow
  const pricesClassOrdered = bookingFormConfig.yachtClasses
  const useYachtClass = bookingFormConfig.useYachtClass

  // initiating an empty array that stores references to our nodes
  const refs = []

  return (
    <div className={resolveVariationClass("section-destination__price-table")}>
      <section className="price-list">
        <div className={"booking-form__body booking-form__body--in-page"}>
          <div className="booking-form__phase-1">
            {mtiPrices && (
              <div className="booking-form__tour-title u-margin-bottom-small">
                <>
                  <Heading
                    as="h2"
                    className={resolveVariationClass("heading-1")}
                  >
                    {mtiPrices.description} Pricing
                  </Heading>
                  <div className="booking-form__info">
                    {renderPriceHeaderDescription(priceTableHeaderDescription)}
                  </div>
                </>
              </div>
            )}
            <div className="booking-form__entries">
              <Entries
                pricesClassOrdered={pricesClassOrdered}
                useYachtClass={useYachtClass}
                entries={mtiPrices}
              />

              {renderBookingExtraNotes(mtiPrices)}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PriceTable
