import { graphql, useStaticQuery } from "gatsby"

// our reusable graphql Query for destinations utlizing predefined fragment
const useFooterQuery = () => {
  const footerData = useStaticQuery(graphql`
    query {
      contentfulWebsiteConfiguration(identifier: { eq: "main" }) {
        ...FooterBox
      }
    }
  `)
  return footerData.contentfulWebsiteConfiguration
}

export default useFooterQuery
