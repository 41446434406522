import { graphql } from "gatsby"

import { useStaticQuery } from "gatsby"

/*
We can also query plugin options in gatsby! :)
This query uses the data defined on `gatsby-config.js` through plugins `options` JSON object.
To see this access the cat or dog app gatsby-config.js.
*/

const useThemeStyleOptionsQuery = () => {
  const query = useStaticQuery(graphql`
    query {
      sitePlugin(name: { eq: "@nt-websites/navigate-theme" }) {
        pluginOptions {
          theme {
            fonts {
              primary
            }
            colors {
              primary
            }

            page {
              tour {
                title {
                  font
                }
              }
            }

            components {
              tripCard  {
                fontColor
                fontFamily
              }
              tealbar {
                color
              }
              header {
                background
              }

             

              heading {
                title {
                  font
                }
                h2 {
                  font
                }
              }
              featuredSection {
                background
              }
              logo {
                topMargin
                width
              }
            }
          }
        }
      }
    }
  `)

  return query.sitePlugin.pluginOptions.theme
}

export default useThemeStyleOptionsQuery
