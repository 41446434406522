import React from "react"

import resolveVariationClass from "../../helpers/theme-variation-style"
import { useWebSiteConfigQuery } from "../../queries/webSiteConfigQueries"
import Heading from "../../shared/heading"

const TextBoxAlt = ({ setModal, buttonSecond, button2Class, titleFirst }) => {
  const playIcon = useWebSiteConfigQuery().sitePlugin.pluginOptions.config
    .playIcon

  return (
    <>
      <Heading
        color="white"
        as="h1"
        title
        className={`header-title ${resolveVariationClass(
          "header-title__main"
        )}`}
      >
        {titleFirst}
      </Heading>
      <div className={resolveVariationClass("header__button-box")}>
        {buttonSecond && (
          <a
            href="#"
            onClick={() => setModal({ open: true })}
            className={button2Class}
          >
            <img className="play-button" src={playIcon} alt="play-button"></img>
            <span className="">{buttonSecond}</span>
          </a>
        )}
      </div>
    </>
  )
}

export default TextBoxAlt
