import React, { useState } from "react"
import resolveVariationClass from "../../helpers/theme-variation-style"
import { Link } from "gatsby"
import { useWebSiteConfigQuery } from "../../queries/webSiteConfigQueries"

const Announcement = ({ children, style }) => {
  const className = resolveVariationClass(`announcement`)
  const linkClassName = resolveVariationClass(`announcement__close`)

  const [open, setOpen] = useState(true)

  const {
    announcementBannerDisplay,
    announcementBannerText,
    announcementBannerLink,
    announcementBannerTextBackgroundColor,
    announcementBannerTextColor,
  } = useWebSiteConfigQuery().contentfulWebsiteConfiguration

  const linkStyle = {
    color: announcementBannerTextColor,
  }

  const bannerStyle = {
    backgroundColor: announcementBannerTextBackgroundColor,
    ...style,
  }

  return (
    <>
      {announcementBannerDisplay && open && (
        <div style={bannerStyle} className={className}>
          <Link
            className="announcement__link"
            style={linkStyle}
            to={announcementBannerLink}
          >
            {announcementBannerText}
          </Link>
          <div onClick={() => setOpen(false)} className={linkClassName}></div>
        </div>
      )}
    </>
  )
}

export default Announcement
