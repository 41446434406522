import React from "react"
import { Link } from "gatsby"
import resolveVariationClass from "../../helpers/theme-variation-style"
import Img from "gatsby-image"
import { useWebSiteConfigQuery } from "../../queries/webSiteConfigQueries"
const Logo = () => {
  // setting our logo based on our environment variable
  const imageLogo = useWebSiteConfigQuery().contentfulWebsiteConfiguration
    .websiteLogo.localFile.childImageSharp.fluid

  return (
    <li className="navigation__item">
      <Link className={resolveVariationClass("header__logo")} to="/">
        <Img fluid={imageLogo} style={{ width: "12rem" }} />
      </Link>
    </li>
  )
}

export default Logo
