import React from "react"
import PropTypes from "prop-types"

import Footer from "../footer/footer"
import Navigation2 from "../header/navigation2"
import Header from "../header/header"
//mobile modules
import NavigationMobile from "./../mobile/navigationMobile"
import AppContext from "../../../app"
//loading fonts
import "../../styles/fonts/_fonts.css"
import "../../styles/main.scss"

const Layout = ({ children }) => {
  Layout.propTypes = {
    children: PropTypes.node.isRequired,
  }

  return (
    <AppContext>
      <Header backgroundColorAlwaysActive={true}>
        <Navigation2 />
        <NavigationMobile />
      </Header>
      <main>{children}</main>
      <Footer />
    </AppContext>
  )
}

export default Layout
