import { ThemeProvider } from "styled-components"
import React from "react"
import PropTypes from "prop-types"
import merge from "lodash.merge"

import appTheme from "./theme"

import useThemeOptionsQuery from "./src/queries/themeOptionsQuery"

const AppContainer = ({ children }) => {
  const siteTheme = useThemeOptionsQuery()

  const theme = merge({}, appTheme, siteTheme)

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppContainer
