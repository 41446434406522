import React, { useCallback, useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { commaHandler } from "../../hooks/commaHandler"
import useThemeRoutesConfigQuery from "../../queries/themeRoutesConfigQuery"
import resolveVariationClass from "../../helpers/theme-variation-style"
import { useWebSiteConfigQuery } from "../../queries/webSiteConfigQueries"

// todo - clean up this file and separate the concerns

const FilteredTour = ({ country, destinationData }) => {
  const theme = process.env.GATSBY_THEME
  const pageConfiguration = useWebSiteConfigQuery().sitePlugin.pluginOptions
    .config
  // TODO - WE SHOULD REPLACE all these repetitive button codes with something better
  const buttonClass = resolveVariationClass("filtered-tour__button")
  const buttonClassActive = resolveVariationClass(
    "filtered-tour__button--active"
  )

  const themeOptionsQueryData = useThemeRoutesConfigQuery()

  // categorizing data on the load
  let groupByData = {
    week: [],
    twoWeeks: [],
    threeWeeks: [],
  }



  // the actual categorizer function
  destinationData.forEach(e => {
    if (e.filterTag === "1 Week") {
      groupByData.week.push(e)
    } else if (e.filterTag === "2 Weeks") {
      groupByData.twoWeeks.push(e)
    } else {
      groupByData.threeWeeks.push(e)
    }
  })

  // setting the state of our filters
  const [filter, setFilter] = useState({
    week: false,
    twoWeeks: false,
    threeWeeks: false,
  })

  const [data, setData] = useState(destinationData)

  // handling the filter functionality
  const handleSubmit = (stateFilter, weekNumber) => {
 
  
    // preventing browser's default behaviour



    // updating our filter state
    const filter = {
      week: weekNumber === 1 ? !stateFilter.week : stateFilter.week,
      twoWeeks: weekNumber === 2 ? !stateFilter.twoWeeks : stateFilter.twoWeeks,
      threeWeeks: weekNumber === 3 ? !stateFilter.threeWeeks : stateFilter.threeWeeks,
    }
    setFilter(
      filter
    
      )

    // creating an empty array that will be later on used to render the dom, this is to avoid mutation
    const filteredData = []
    

    const hasActiveFilter = Object.values(filter).reduce((value, filter, index) => {
        if(filter) {
        return value + 1
      } 
      return value
    }, 0 )



    if(!hasActiveFilter) {
      Object.values(groupByData).forEach(tours => {
        filteredData.push(...tours)
      }) 


    }


    // handling filter pushing gracefully
    for (let key in filter) {
      if (filter[key]) {
        filteredData.push(...groupByData[key])
      }
    }



     setData(filteredData)

  
  }

  let currency

  // rendering elements out of our data file
  const renderTours = data => {
    return (
      data
        /*.filter(element => {
        return element.node.destinationCountry === country
      })*/
        .map((element, idx) => {
          // logic for adding currency text
          element.destinationCountry === "new-zealand"
            ? (currency = ["NZD", "$"])
            : element.destinationCountry === "australia"
            ? (currency = ["AUD", "$"])
            : (currency = ["EUR", "€"])

          return (
            <div
              key={idx}
              className="filtered-tour"
              to={`destinations/${country}/${element.slug}`}
            >
              <figure className="filtered-tour__image-container">
                <Img
                  fluid={
                    element.bannerImages[0].localFile.childImageSharp.fluid
                  }
                  alt={element.title}
                />
                <figcaption
                  className={resolveVariationClass("trips__duration")}
                >
                  <span className="trips__duration-days">
                    {element.duration}
                  </span>
                  <span className="trips__duration-text">days</span>
                </figcaption>
              </figure>
              <div className="filtered-tour__description">
                <h3 className={resolveVariationClass("heading-2")}>
                  {element.title}
                </h3>
                <h5 className="filtered-tour__description-subtitle">
                  {element.route}
                </h5>

                {/*element.numberOfCountries !== null ? (
                  <h5 className="filtered-tour__description-subtitle">
                    {`${element.numberOfCountries} countries`}
                  </h5>
                ) : null*/}

                <span className={resolveVariationClass("heading-4")}>
                  {`from ${currency[1]}${commaHandler(element.priceFrom)} ${
                    currency[0]
                  }`}
                </span>
              </div>
              <div className="filtered-tour__svg-map">
                <img
                  src={element.svgMap.localFile.publicURL}
                  alt={element.title}
                />
              </div>
              <Link
                aria-current="page"
                className={`${resolveVariationClass(
                  "btn"
                )} tablet-green-button`}
                to={`${themeOptionsQueryData.destinationCountryRoutePrefix}${country}/${element.url}`}
              >
                {pageConfiguration.destinationPage.buttonCardText}
              </Link>
            </div>
          )
        })
    )
  }

  const isFilterActive = useCallback((weekNumber) => {
    switch(weekNumber) {
      case 1: 
       return !!filter.week
       case 2: 
       return !!filter.twoWeeks
       case 3: 
       return !!filter.threeWeeks
       default: 
       return false
    }
    
  },[filter]);


  const renderWeekText = (weekNumber) => {
    switch (weekNumber) {
      case 1: 
        return `${weekNumber} week`;
        case 2: 
        return `${weekNumber} weeks`;
        case 3: 
        return `${weekNumber}+ weeks`;
        default: 
        return `${weekNumber} weeks`;

    } 
   
  }


  const renderWeekButtonFilter = (weekNumber) => {
    return (
      <>
      <button
      className={
        isFilterActive(weekNumber)
          ? `filtered-tour__button ${buttonClassActive}`
          : `filtered-tour__button ${buttonClass}`
      }
      onClick={() =>handleSubmit(filter,weekNumber)}
    >
      <span>{renderWeekText(weekNumber)}</span>
      </button>
      </>
    )
    }



  return (
    <div className="section-filtered-tour">
      <div className="filtered-tour__container">
        {theme !== "ms" ? (
          <div className="filtered-tour__head">
            <h3 className={resolveVariationClass("paragraph")}>
              How long are you travelling for?
            </h3>
            {renderWeekButtonFilter(1)}
            {renderWeekButtonFilter(2)}
            {renderWeekButtonFilter(3)}
          </div>
        ) : null}
        {renderTours(data)}
      </div>
    </div>
  )
}

export default FilteredTour
