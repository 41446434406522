import React from "react"

import BoxText from "../boxes/boxText"
import resolveVariationClass from "../../helpers/theme-variation-style"
import Heading from "../../shared/heading"

const Highlight = ({ title, images, titles, descriptions }) => {
  const renderHighlights = () => {
    return titles.map((higlight, idx) => {
      return (
        <BoxText
          key={idx}
          imageData={images[idx].localFile.childImageSharp.fluid}
          title={titles[idx]}
          description={descriptions[idx]}
        />
      )
    })
  }
  return (
    <section className="section-destination__highlight">
      <div className="highlight-box__container">
        <Heading as="h2" className={resolveVariationClass("heading-1")}>
          {title}
        </Heading>
        {renderHighlights()}
      </div>
    </section>
  )
}

export default Highlight
