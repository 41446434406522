import React, { useState, useRef } from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import { submitContactRequest } from "../../services/api"
import Error from "../destinations/error"
import resolveVariationClass from "../../helpers/theme-variation-style"
import { TAG_MANAGER_TRACKER } from "../../config/tag-manager"
import ReCAPTCHA from "react-google-recaptcha"

// Our Schema validation logics here
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "First name must be at least a character")
    .required("Please fill in the name filed"),
  email: Yup.string()
    .email("Please enter a valid email email address")
    .required("Email adress is required"),
  message: Yup.string().required("Please enter in your message"),
})

// final data to be sent to the API
let finalAPI

const FooterForm = () => {
  const captchaRef = useRef()
  const [isLoading, setLoading] = useState(false)
  // object that we use to synthesize later with form fields later
  const partialData = { productId: process.env.GATSBY_PRODUCT_ID }

  // setting the state for the success message
  const [{ success, message }, setSuccess] = useState({
    success: false,
    message: null,
  })

  const [error, setError] = useState("")

  return (
    <>
      <input
        id="footer__input"
        className="footer__input mobile-yes"
        type="checkbox"
      />
      <label
        htmlFor="footer__input"
        className={`btn ${resolveVariationClass("btn-mobile")} mobile-yes`}
      >
        send message
      </label>
      <div className="footer__form ">
        <h6 className="footer__trips-header">send us a message</h6>
        <p className="u-padding-bottom-small u-font-size-tiny">
          Complete the form below and one of our travel advisors will be in
          touch shortly.
        </p>
        <div className="footer__form-fields">
          {success ? (
            <h3
              className={`u-padding-top-medium 
            ${resolveVariationClass(
              "get-in-touch__form-submit-response-message"
            )}`}
            >
              {message}
            </h3>
          ) : (
            <Formik
              initialValues={{
                name: "",
                email: "",
                message: "",
                consent: false,
              }}
              validationSchema={validationSchema}
              onSubmit={async values => {
                setLoading(true)
                setError("")

                const captchaValue = await captchaRef.current.executeAsync()
                captchaRef.current.reset()

                Object.assign(values, {
                  captcha: captchaValue,
                })
                finalAPI = { ...values, ...partialData }
                try {
                  const response = await submitContactRequest(
                    JSON.stringify(finalAPI)
                  )

                  setLoading(false)

                  setSuccess({
                    success: true,
                    message: response.data.data.message,
                  })
                } catch (error) {
                  const errorMsg =
                    error && error.response
                      ? error.response.data.data.message
                      : "Sorry, something went wrong. Please try again or contact us."
                  setLoading(false)
                  setError(errorMsg)
                }
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form className="gap-3 footer__contact-form">
                  <div>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Name"
                      className="footer__form-input foooter__form-input-name"
                    />
                    {touched.name && errors.name && (
                      <span className="block text-red-600">{errors.name}</span>
                    )}
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="email"
                      placeholder="Email Address *"
                      className={
                        errors.email
                          ? "booking-form__fields booking-form__fields--error"
                          : "booking-form__fields"
                      }
                    ></Field>
                    {touched.email && errors.email && (
                      <span className="block text-red-600">{errors.email}</span>
                    )}
                  </div>
                  <div className="booking-details__fields-container">
                    <Field
                      component="textarea"
                      name="message"
                      placeholder="Comments"
                      className="footer__form-input--big "
                    ></Field>
                    <Error
                      touched={touched.comments}
                      message={errors.comments}
                    />
                  </div>
                  <div id="captcha">
                    <ReCAPTCHA
                      ref={captchaRef}
                      size="invisible"
                      sitekey={process.env.GATSBY_INVISIBLE_CAPTCHA_PUBLIC_KEY}
                    />
                  </div>
                  <div className="footer__form-consent-box">
                    <Field
                      id="consent"
                      name="consent"
                      type="checkbox"
                      className="footer__form-radio"
                      required
                    ></Field>
                    <label
                      className="footer__form-consent u-color-gray"
                      htmlFor="consent"
                    >
                      I accept the&thinsp;
                      <a
                        className={resolveVariationClass("link")}
                        href={`${process.env.GATSBY_SITE_URL}/terms-conditions`}
                        target="_blank"
                      >
                        terms and conditions
                      </a>
                    </label>
                  </div>
                  {error && (
                    <div className="bg-red-200 text-lg border mb-4 uppercase font-bold text-red-900 border-red-600 p-8">
                      {error}
                    </div>
                  )}

                  <button
                    disabled={isLoading}
                    id={TAG_MANAGER_TRACKER.CONTACT_FOOTER_BUTTON}
                    className={resolveVariationClass("btn btn--green-footer")}
                    type="submit"
                  >
                    {isLoading ? `Loading...` : `Submit`}
                  </button>

                  <div className="mt-8 font-thin">
                    This site is protected by reCAPTCHA and the Google{" "}
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      className="underline cursor-pointer"
                      href="https://policies.google.com/privacy"
                    >
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      className="underline cursor-pointer"
                      href="https://policies.google.com/terms"
                    >
                      Terms of Service
                    </a>{" "}
                    apply.
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  )
}

export default FooterForm
