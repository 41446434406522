import React from "react"
import { useFooterQuery, resolveVariationClass } from "@nt-websites/navigate-theme"

const FooterLogoContainer = () => {
  const footerQuery = useFooterQuery()
  const logos = footerQuery.footerLogos

  const renderLogos = () =>
    logos.map((e, idx) => (
      <div key={idx}>
        <img style={{width: '100%'}} src={e.localFile.publicURL}></img>
      </div>
    ))

  return <section className={resolveVariationClass("footer__logo-container")}>{renderLogos()}</section>
}

export default FooterLogoContainer
