import React from "react"
import { withPrefix } from "gatsby"
import resolveVariationClass from "../../helpers/theme-variation-style"
import { useWebSiteConfigQuery } from "../../queries/webSiteConfigQueries"
import styled from "styled-components"
import themeGet from "@styled-system/theme-get"
const GreenBar = () => {
  // TODO CLEAN UP ALL THE CALLS TO GATBSY_THEME ENV VARIABLE

  const greenBar = useWebSiteConfigQuery().sitePlugin.pluginOptions.config
    .greenBar

  const iconClass = `${resolveVariationClass(
    "svg-icon--green-banner-svg-logo"
  )}`

  const GreenBar = styled.section`
    background: ${themeGet("components.tealbar.color")};
  `

  return (
    <GreenBar className={resolveVariationClass("green-bar")}>
      <div className="green-bar__container">
        {greenBar.icon && greenBar.icon !== "" && (
          <svg className={iconClass}>
            <use xlinkHref={withPrefix(`sprite.svg#icon-${greenBar.icon}`)} />
          </svg>
        )}
        <h2 className="heading-alternative heading-alternative--white">
          {greenBar.text}
        </h2>
      </div>
    </GreenBar>
  )
}

export default GreenBar
